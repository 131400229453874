<template>
    <div>
        <Header :showMainNav="false" :showUserNav="false" />
        <div class="forget-psw">
            <CardBox :text="formData.type.title">
                <template #contain>
                    <p v-if="showFormBox && route.path === '/active/account'" class="account-title">
                        {{ formData.from === 'email' ? t('tip.activeSysAcount') : t('tip.activeInfoAcount') }}
                    </p>
                    <Form
                        v-if="showFormBox"
                        ref="formDom"
                        class="form"
                        :model="formData"
                        :rules="formRule"
                        autocomplete="off"
                        @keyup.enter="handleSubmit"
                        :label-col-props="{ span: 9 }"
                        :wrapper-col-props="{ span: 15 }">
                        <FormItem :label="route.path === '/alter/email' ? t('login.newEmail') : t('login.email')"> {{ formData.email }} </FormItem>
                        <FormItem :label="t('login.name')" v-if="route.path === '/find/password' || route.path === '/alter/email' || formData.from === 'info'">
                            {{ formData.username }}
                        </FormItem>
                        <FormItem field="username" :validate-trigger="['blur']" :label="t('user.name')" v-else>
                            <Input v-model.trim="formData.username" spellcheck="false" :placeholder="t('tip.userNameTip')" :maxlength="50" />
                        </FormItem>
                        <FormItem :label="t('login.password')" field="password" :validate-trigger="['blur']" v-if="formData.from === 'email'">
                            <Input v-model.trim="formData.password" type="password" spellcheck="false" :placeholder="t('holder.regPasswordHolder')" />
                        </FormItem>
                        <FormItem :label="t('login.confirmPsw')" field="password1" :validate-trigger="['blur']" v-if="formData.from === 'email'">
                            <Input v-model.trim="formData.password1" type="password" spellcheck="false" :placeholder="t('holder.regPasswordHolder')" />
                        </FormItem>
                        <FormItem :label="t('login.password')" field="infoPassword" :validate-trigger="['blur']" v-if="formData.from === 'info'">
                            <Input v-model.trim="formData.infoPassword" type="password" spellcheck="false" :placeholder="t('holder.infoPasswordHolder')" />
                        </FormItem>
                        <FormItem
                            :isRequiredError="true"
                            field="phone"
                            :label="t('login.phone')"
                            :validate-trigger="['blur']"
                            v-if="route.path !== '/find/password'">
                            <Input v-model.trim="formData.phone" spellcheck="false" :placeholder="t('holder.phoneHolder')" />
                        </FormItem>
                        <FormItem field="agreed" v-if="route.path === '/active/account'">
                            <div class="flex-align-center">
                                <Checkbox v-model="formData.agreed" style="margin-right: 10px"></Checkbox>
                                <span v-if="$store.getters.isEnglish">
                                    I have agreed the <a href="/terms/EULA" target="blank">EULA</a> and
                                    <a href="/terms/PrivacyPolicy" target="blank">Privacy Policy</a>
                                </span>
                                <span v-else>
                                    我已同意并遵守<a href="/terms/EULA" target="blank">用户协议</a>、<a href="/terms/PrivacyPolicy" target="blank">隐私政策</a>
                                </span>
                            </div>
                        </FormItem>
                        <FormItem field="pass">
                            <Button class="submit-btn btn-fill" type="primary" @click="handleSubmit" :loading="loading">
                                {{ t('submit') }}
                            </Button>
                        </FormItem>
                    </Form>
                    <div v-else class="info-box msg-box">
                        <div v-if="successStatus">
                            <icon-check-circle-fill />
                            <p>{{ formData.type.tip }}</p>
                            <Button class="submit-btn btn-fill" type="primary" @click="handleView" v-if="route.path === '/invite/user'">
                                {{ t('goToView') }}
                            </Button>
                            <Button class="submit-btn btn-fill" type="primary" @click="handleLogin" v-else>
                                {{ t('login.login') }}
                            </Button>
                        </div>
                        <div v-else>
                            <icon-close-circle-fill />
                            <p>{{ errorMsg }}</p>
                        </div>
                    </div>
                </template>
            </CardBox>
        </div>
    </div>
</template>
<script>
import { defineComponent, reactive, inject, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Form } from '@arco-design/web-vue'
import { useStore } from 'vuex'
import CardBox from '@/components/CardBox.vue'
import Header from '@/views/layout/Header.vue'
import Sha256 from 'crypto-js/sha256'
import * as reg from '@/utils/formReg'
export default defineComponent({
    components: {
        Header,
        CardBox
    },
    setup() {
        const t = inject('$t')
        const http = inject('$http')
        const $msg = inject('$message')
        const formDom = ref(null || Form)
        const router = useRouter()
        const store = useStore()
        const route = useRoute()
        const showFormBox = ref(true)
        const loading = ref(false)
        const successStatus = ref(true)
        const errorMsg = ref('')
        const pathMap = {
            '/active/account': {
                title: t('login.activeAccount'),
                tip: t('tip.activatedSuccessTip'),
                getInfo: 'getActivationUserInfo',
                setInfo: 'activateUser'
            },
            '/find/password': {
                title: t('login.resetPassword'),
                tip: t('tip.resetPswTip'),
                getInfo: 'getResetPasswordUserInfo',
                setInfo: 'resetPassword'
            },
            '/alter/email': {
                title: t('login.modifyEmail'),
                tip: t('tip.bindEmailTip'),
                getInfo: 'getChangeEmailInfo',
                setInfo: 'changeEmailInfo'
            },
            '/invite/user': {
                title: t('invite'),
                tip: t('tip.inviteSuccTip'),
                getInfo: 'getInviteUserInfo'
            }
        }
        const formData = reactive({
            from: 'email',
            teamName: '',
            email: '',
            username: '',
            password: '',
            phone: '',
            password1: '',
            infoPassword: '',
            roleName: '',
            type: pathMap[route.path]
        })
        const formRule = {
            username: [
                { required: true, message: t('tip.emptyTip', { key: t('user.name') }) },
                { required: true, match: reg.usernameReg, message: t('tip.nameRegTip') }
            ],
            password: [
                { required: true, message: t('tip.emptyTip', { key: t('login.password') }) },
                { maxLength: 20, minLength: 6, message: t('tip.pswTip') },
                { match: reg.passwordReg, message: t('tip.pswRegTip') }
            ],
            password1: [
                { required: true, message: t('tip.emptyTip', { key: t('login.confirmPsw') }) },
                { required: true, message: t('tip.confirmPswTip') },
                {
                    validator: (val, callback) => {
                        if (val !== formData.password) {
                            callback(t('tip.pswDifferentTip'))
                        } else {
                            callback()
                        }
                    }
                }
            ],
            infoPassword: [{ required: true, message: t('tip.emptyTip', { key: t('login.password') }) }],
            phone: [{ maxLength: 20, minLength: 0, message: t('tip.phoneTip') }],
            agreed: [{ type: 'boolean', true: true, message: t('tip.termTip') }]
        }
        const setInfo = (data) => {
            if (data.code === 100703) {
                $msg.error(data.message)
            } else {
                errorMsg.value = data.message
                showFormBox.value = false
                successStatus.value = false
            }
        }
        const getUserData = (code) => {
            http.account[formData.type.getInfo](code)
                .then(({ data }) => {
                    if (route.path === '/invite/user' && !data.enable) {
                        //未激活用户
                        router.push(`/active/account?code=${data.code}`)
                        formData.type = pathMap['/active/account']
                        getUserData(data.code)
                    } else if (route.path === '/invite/user') {
                        // 已激活用户
                        const userData = { ...store.state.userData }
                        userData.teamId = data.teamId
                        store.commit('setUserData', userData)
                        formData.type.tip = t('tip.inviteSuccTip', { name: data.teamName })
                        showFormBox.value = false
                        successStatus.value = true
                    } else {
                        formData.from = data.from || 'email'
                        formData.teamName = data.teamName
                        formData.roleName = data.roleName
                        formData.email = data.newEmail || data.email
                        formData.username = data.username || ''
                    }
                })
                .catch(({ response }) => {
                    setInfo(response.data)
                })
        }
        getUserData(route.query.code)
        const handleLogin = () => {
            store.commit('clearUserData')
            router.push(`/login?from=${formData.from}`)
        }
        const handleView = () => {
            router.push(`/login?from=${formData.from}`)
        }
        const handleSubmit = () => {
            formDom.value.validate((errors) => {
                if (!errors) {
                    const params = {
                        username: formData.username,
                        agreed: formData.agreed
                    }
                    if (formData.from === 'info') {
                        params.password = formData.infoPassword
                    } else {
                        params.password = Sha256(formData.password).toString()
                    }
                    if (formData.type.setInfo !== 'resetPassword') params.phone = formData.phone
                    loading.value = true
                    http.account[formData.type.setInfo](route.query.code, params)
                        .then(() => {
                            loading.value = false
                            showFormBox.value = false
                            successStatus.value = true
                        })
                        .catch(({ response }) => {
                            loading.value = false
                            setInfo(response.data)
                        })
                }
            })
        }
        return {
            formDom,
            formData,
            formRule,
            t,
            route,
            loading,
            showFormBox,
            successStatus,
            errorMsg,
            handleLogin,
            handleView,
            handleSubmit
        }
    }
})
</script>
<style lang="less" scoped>
.account-title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #2b32c9;
    padding-left: 30px;
}
.forget-psw {
    margin: 30px 12%;
    background: #ffffff;
    min-width: 600px;
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px rgba(177, 177, 177, 0.25);
    .form {
        margin: auto;
        padding-right: 190px;
        padding-top: 40px;
        padding-bottom: 60px;
        width: 800px;
        .submit-btn {
            margin-top: 10px;
            width: 100%;
        }
    }
    .info-box {
        text-align: center;
        padding: 20px 0;
        .arco-icon {
            font-size: 30px;
            &.arco-icon-check-circle-fill {
                color: #0cb339;
            }
            &.arco-icon-close-circle-fill {
                color: red;
            }
        }
        p {
            margin: 20px 0;
        }
        .submit-btn {
            padding: 0 40px;
        }
    }
    .msg-box {
        padding: 60px;
    }
    a {
        text-decoration: underline;
    }
}
</style>
